#materials {
  background-color: #fce0cc;
  position: relative;
  padding-bottom: 40px;

  .section-content {
    flex-direction: row-reverse;
    padding-top: 90px;
    padding-bottom: 40px;
  }
  .content-text {
    max-width: 480px;
    &__paragraph {
      padding-bottom: 14px;
    }
    &__title {
      font-weight: 600;
      padding: 24px 0 20px;
    }
    &__subparagraph {
      font-size: 12px;

      span {
        font-weight: 500;
        font-style: italic;
      }
    }
  }

  .content-text__paragraph + .content-text__subparagraph {
    margin-top: -10px;
  }
  .bottom {
    margin-top: 10px;
    padding-bottom: 20px;
    display: flex;
    justify-content: space-between;
  }
  .bottom__title {
    font-size: 12px;
    text-align: center;
    line-height: 18px;
    font-weight: 500;
    padding-bottom: 7px;
  }
  .bottom__btn {
    margin: 5px;
    width: 190px;
    height: 46px;
    background: linear-gradient(90deg, #f47921, #f47921);
    border-radius: 10px;
    font-weight: 600;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #fff;
    transition: 0.2s;
    font-size: 14px;
    line-height: 24px;
  }

  .bottom__btn-outline {
    background: transparent;
    box-sizing: border-box;
    color: #f47921;
    transition: 0.2s;
    font-size: 18px;
    line-height: 27px;
    border: 2px solid #f47921;
    border-radius: 10px;
  }

  .bottom__btn:hover {
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
    transition: 0.2s;
  }
  .bottom__btn:active {
    background: #ff9244;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
    transition: 0s;
  }

  .bottom__btn-outline:active {
    background: transparent;
    opacity: 0.7;
  }
}

#materials:before {
  content: "";
  padding-top: 1.5%;
  background: linear-gradient(to left top, transparent 48.5%, #fff 50%) left / 100% 100% no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

#materials::after {
  content: "";
  padding-top: 1.5%;
  background: linear-gradient(to left top, transparent 48.5%, #fce0cc 50%) left / 100% 100% no-repeat;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
}
