.big-1 {
  grid-column: 2/4;
}
.big-2 {
  grid-column: 1/3;
}
@media screen and (max-width: 1099px) {
  .wraper {
    padding: 0 20px;
  }
  .references {
    .references-grid {
      grid-template-columns: 1fr 1fr;
      .big-1,
      .big-2,
      .grid-item:not(.small):nth-child(7) {
        grid-column: 1/3;
      }
      .small-items {
        grid-column: 1/3;
        grid-template-columns: 1fr 1fr 1fr;
      }
    }
  }
  #header {
    background: none;
    .wraper {
      height: 48px;
      margin: 10px;
      border-radius: 10px;
      background: #f47921;
      overflow: hidden;
      padding: 0;
      z-index: 9997;
    }
    .header-logo {
      width: 65px;
      height: 48px;
      padding: 0;
      z-index: 9999;
    }
    .header-logo img {
      width: 49px;
      height: 32px;
      margin: 8px;
    }
    .burger {
      background: url("/img/burger.svg") no-repeat center center;
      width: 23px;
      height: 15px;
      padding: 0 10px;
    }
    .header-nav {
      display: block;
      position: fixed;
      overflow: hidden;
      width: calc(100% - 20px);
      top: 49px;
      height: 0;
      transition: height 0.4s linear;
      z-index: 9998;
      .nav-list {
        padding: 6px 0 0;
        background: #f47921;
        display: block;

        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
        .nav-item {
          text-align: center;
          position: relative;
          a {
            padding: 15px 0 10px;
            display: block;
          }
        }
        .nav-item:before {
          content: "";
          background: #fff;
          position: absolute;
          width: calc(100% - 20px);
          height: 3px;
          border-radius: 10px;
          top: 0;
          left: 10px;
        }
      }
    }
    .active {
      height: 100%;
      transition: height 0.4s linear;
    }
  }
  #begin {
    margin-top: 0;
  }
  #impressum {
    padding-top: 50px !important;
  }
}

@media screen and (max-width: 768px) {
  .wraper {
    padding: 0 10px;
  }
  .section-title {
    flex-wrap: wrap;
    padding-top: 58px !important;
  }

  .begin-bottom__btn {
    width: 100%;
  }

  #contact {
    .wraper {
      padding-top: 80px;
    }
  }

  .title-quote {
    margin-left: auto;
    text-align: right;
    padding-top: 40px;
    &__text {
      font-size: 30px;
    }
  }
  .section-content {
    flex-wrap: wrap;
    .content-text,
    .content-image {
      margin: 0 auto;
    }
  }
  .references {
    .references-grid {
      grid-template-columns: 1fr 1fr;
      .small-items {
        grid-column: 1/3;
        grid-template-columns: 1fr 1fr;
      }
    }
  }
  .contact-content {
    flex-wrap: wrap;
    div {
      margin: 0 auto;
    }
  }

  #begin {
    background-position-x: 100px;
    background-position-y: 60%;
  }
  .design {
    color: #fff;
    background: none;
    background-color: #1a1a1a;
  }
  .form {
    margin-top: 50px !important;
    &-description {
      margin-top: 10px !important;
    }
  }
}

@media screen and (max-width: 480px) {
  #begin {
    background-position-x: 70px;
    background-position-y: 40%;
  }
  #materials .bottom__btn {
    width: 165px;
    font-size: 12px;
    height: 46px;
  }

  #materials .bottom__btn-outline {
    font-size: 16px;
  }

  .footer {
    .wraper {
      .footer-mail {
        display: none;
      }
    }
  }
  section {
    .begin-top {
      &__title {
        font-size: 40px;
      }
      &__text {
        font-size: 20px;
        line-height: 34px;
      }
    }
    .wraper {
      .content-text__list {
        padding-left: 10px;
      }
      .content-image {
        margin: 0 -10px;
        max-width: 100vw;
        height: auto;
        img {
          max-width: 100vw;
          height: auto;
        }
      }
    }
  }
  .section-content {
    padding-top: 55px !important;
  }
  .references {
    .references-grid {
      grid-template-columns: 1fr;
      .big-1,
      .big-2,
      .grid-item:not(.small):nth-child(7) {
        grid-column: 1/2;
      }
      .small-items {
        grid-column: 1/2;
        grid-template-columns: 1fr;
      }
      .grid-item {
        padding: 20px;
        width: calc(100% - 40px);
        .item-head__title {
          font-size: 90%;
        }
        .item-body__text {
          font-size: 13px;
        }

        .item-footer {
          width: calc(100% - 40px);
        }
        .item-footer__btn {
          height: 40px;
          width: 100%;
        }
        .item-footer__btn::before {
          top: 13px;
        }
      }
      .more {
        padding: 30px 0;
      }
      .footer-mail {
        display: none;
      }
    }
  }
  section,
  .section-content,
  .calendar,
  .print,
  .concept {
    padding-bottom: 0 !important;
  }
  section::before,
  section::after {
    padding: 0 !important;
  }
  .content-text__list {
    flex-wrap: wrap;
  }
  .begin-bottom {
    margin-top: 100px;
  }
}
@media screen and (max-width: 400px) {
  .begin-top {
    &__title {
      font-size: 30px !important;
      span {
        font-size: 30px !important;
      }
    }
    &__text {
      font-size: 20px !important;
    }
  }
  section .grid-item {
    padding: 10px !important;
    width: calc(100vw - 40px) !important;
    .item-footer {
      width: calc(100% - 20px) !important;
      bottom: 10px;
    }
  }
  #materials .bottom__btn-outline {
    width: 130px;
  }
}
