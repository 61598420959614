.design {
  color: #fff;
  background-color: #1a1a1a;
  background: #1a1a1a url("/img/line-2.svg") no-repeat center top;
  .section-content {
    flex-direction: row-reverse;
    padding-bottom: 40px;
  }
  .content-text {
    max-width: 553px;
    &__list {
      display: flex;
    }
    &__list-column:first-child {
      max-width: 280px;
    }
  }
}
