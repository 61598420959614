.references {
  background: url("/img/line-4.svg") no-repeat center, url("/img/clothes_button-2.png") no-repeat 6% 75%;
  padding-bottom: 90px;
  position: relative;
  .title-quote {
    text-align: right;
  }
  &-title {
    padding-top: 90px;
    width: 100%;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
  }
  &-grid {
    padding-top: 75px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 30px;
    grid-row-gap: 30px;
  }
}

.grid-item {
  padding: 30px 36px;
  background: #f1f1f1;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  min-height: calc(450px - 30px * 2);
  position: relative;
  .item {
    &-head {
      display: flex;
      align-items: center;
      margin-bottom: 30px;
      &__image {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        background-color: #fff;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        img {
          width: inherit;
        }
      }
      &__title {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        padding-left: 25px;
      }
    }
    &-body {
      margin-bottom: 60px;
      &__text {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
      }
      &__ref {
        padding-top: 30px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
      }
    }
    &-footer {
      position: absolute;
      bottom: 30px;
      &__btn {
        position: relative;
        width: 240px;
        height: 48px;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        text-transform: uppercase;
        color: #ffffff;
        text-align: center;
        background: #4865ff;
        border-radius: 10px;
        padding-left: 30px;
        transition: 0.4s;
      }
      &__btn:hover {
        box-shadow: 0px 3px 15px rgba(81, 81, 81, 0.44);
        transition: 0.2s;
      }
      &__btn:before {
        content: "";
        position: absolute;
        background: url("/img/link-icon.svg");
        width: 12px;
        height: 12px;
        top: 17px;
        left: 30px;
      }
    }
  }
}
.grid-item:nth-child(2) {
  img {
    width: 60px !important;
  }
}
.grid-item:nth-child(5) {
  img {
    margin: 14px 0 0;
  }
}
.small-items {
  grid-column: 1/4;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
}
.small {
  padding: 20px;
  min-height: calc(144px - 20px);
  .item-head__title {
    font-size: 14px;
    padding-left: 10px;
  }
  .item-head__image {
    min-width: 62px;
    width: 62px;
    height: 62px;
  }
  .item-footer {
    width: calc(100% - 20px * 2);
    bottom: 20px;
  }
  .item-footer__btn {
    height: 40px;
    width: 100%;
  }
  .item-footer__btn:before {
    top: 12px;
    left: 20px;
  }
}

.more {
  padding-top: 72px;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  background: linear-gradient(88.99deg, #f47d21 0%, #f46021 99.04%);
  border-radius: 10px;
  color: #fff;
  text-align: center;
}

.references::after {
  content: "";
  padding-top: 100px;
  background: linear-gradient(to bottom left, transparent 49.5%, #fce0cc 50%) left / 50.025% 100% no-repeat,
    linear-gradient(to bottom right, transparent 49.5%, #fce0cc 50%) right / 50.025% 100% no-repeat;
  position: absolute;
  top: calc(100% - 50px);
  left: 0;
  right: 0;
  z-index: 9989;
}
