.print {
  background: url("/img/line-3.svg") no-repeat center;
  padding-bottom: 110px;
  .section-title {
    padding-top: 110px;
  }
  .section-content {
    padding-top: 120px;
  }
}
