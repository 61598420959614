.footer {
  background: #383838;

  height: 73px;
  a {
    color: #ffffff;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    text-decoration: none;
  }
  &-logo {
    width: 160px;
    height: 73px;

    background: #ffffff;
    &__image {
      width: 91px;
      height: 56px;
      padding: 6px 35px;
    }
  }
  &-impressum {
    padding-left: 30px;
  }
  &-mail {
    margin-left: auto;
  }
  .wraper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}
