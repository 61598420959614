#header {
  background: #f47921;
  height: 106px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;

  .wraper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .header-logo {
    padding: 12px 17px 13px 16px;
    background: #fff;
    img {
      width: 127px;
      height: 78px;
    }
  }
  .header-nav {
    // margin-top: 35px;
    width: 100%;
  }
  .nav-list {
    padding-left: 15px;
    padding-right: 7px;
    display: flex;
    justify-content: space-around;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    a {
      color: #ffffff;
      text-decoration: none;
    }
    a:hover {
      text-decoration: underline;
    }
  }
}
