.calendar {
  padding-bottom: 120px;
  background: url("/img/clothes_button-2.png") no-repeat 6% 6%;
  .section-title {
    padding-top: 155px;
  }
  .section-content {
    padding-top: 55px;
    .content-text {
      max-width: 480px;
    }
  }
}
