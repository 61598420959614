#begin {
  background: url("/img/start-bg-2.png") no-repeat center, #f6f6f6 url("/img/start-bg-1.jpg") no-repeat center;
  padding-top: 95px;
  background-size: contain;
  margin-top: 100px;
}
.begin {
  &-top {
    &__title {
      font-weight: 800;
      font-size: 64px;
      color: #f47921;
    }
    &__text {
      margin-top: 55px;
      font-weight: 500;
      font-size: 36px;
      line-height: 54px;
      color: #383838;
    }
  }
  &-bottom {
    margin-top: 200px;
    padding-bottom: 50px;
    &__text {
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      color: #454545;
    }
    &__btn {
      margin-top: 27px;
      width: 292px;
      height: 70px;
      background: linear-gradient(90deg, #f47921 0%, #f47921 100%);
      box-shadow: 0px 3px 15px rgba(81, 81, 81, 0.44);
      border-radius: 10px;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: #ffffff;
      transition: 0.2s;
    }
    &__btn:hover {
      box-shadow: 0px 3px 15px rgba(81, 81, 81, 0.7);
      transition: 0.2s;
    }
    &__btn:active {
      background: #ff9244;
      box-shadow: 0px 0px 0px rgba(255, 255, 255, 0);
      transition: 0s;
    }
  }
}
