#personally {
  background: url("/img/clothes_button-1.png") no-repeat 96% 6%;
  position: relative;
  padding-bottom: 80px;
  background-color: #fce0cc;
  .section-content {
    flex-direction: row-reverse;
    padding-bottom: 40px;
    .bold {
      font-style: italic;
      font-weight: bold;
      font-size: 18px;
      line-height: 27px;
    }
    .content-text {
      padding-top: 30px;
      max-width: 460px;
      padding-right: 20px;
    }
  }
}

#personally:before {
  content: "";
  padding-top: 3%;
  background: linear-gradient(to right top, transparent 48.5%, #fff 50%) left / 100% 100% no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

#personally::after {
  content: "";
  padding-top: 3%;
  background: linear-gradient(to right top, transparent 48.5%, #fce0cc 50%) left / 100% 100% no-repeat;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
}
