.contact {
  background-color: #fce0cc;
}
#contact {
  position: relative;
}

.contact {
  .wraper {
    padding-top: 20px;
    margin-top: 50px;
    padding-bottom: 55px;
  }
  &-content {
    display: flex;
    justify-content: space-between;
  }
  &-title {
    margin-top: 40px;
    font-weight: 600;
    font-size: 36px;
    line-height: 54px;
    width: 100%;
    text-align: center;
  }
  &-description {
    width: 100%;
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    margin-top: 30px;
    margin-bottom: 82px;
  }
}

.contacts {
  &-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 36px;
    margin-bottom: 55px;
  }
  &-paragraph {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 27px;
  }
}
.form {
  max-width: 445px;
  &-title {
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 36px;
    margin-bottom: 20px;
  }
  &-description {
    font-weight: 500;
    font-size: 9px;
    line-height: 12px;
  }
  &-body {
    margin-bottom: 26px;
    background: #ffffff;
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 30px;
    input,
    textarea {
      background: #ffffff;
      border: 2px solid #c1c1c1;
      box-sizing: border-box;
      border-radius: 10px;
      height: 60px;
      width: 100%;
      margin-bottom: 16px;
      font-weight: 500;
      font-size: 14px;
      line-height: 27px;
      color: #757575;
      padding-left: 32px;
    }
    textarea {
      padding-top: 10px;
      height: 130px;
    }
  }
  .content-text__paragraph {
    padding-bottom: 0;
  }
  &-btn {
    display: block;
    margin: 0 auto;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    background: linear-gradient(90deg, #f47921 1.73%, #ff6a3c 100%);
    box-shadow: 0px 3px 15px #ffcaa3;
    border-radius: 10px;
    width: 231px;
    height: 60px;
    color: #fff;
    transition: 0.2s;
  }
  &-btn:active {
    background: #ff9244;
    box-shadow: 0px 0px 0px rgba(255, 255, 255, 0);
    transition: 0s;
  }
  &-btn:hover {
    box-shadow: 0px 3px 15px rgba(81, 81, 81, 0.44);
    transition: 0.2s;
  }
}

.facebook {
  margin-top: 50px;
}
