.concept {
  .content-image {
    width: 561px;
    height: 570px;
    img {
      width: 561px;
      height: 570px;
    }
  }
}
.design {
  .content-image {
    width: 475px;
    height: 475px;
    img {
      width: 475px;
      height: 475px;
    }
  }
}
.print {
  .content-image {
    width: 635px;
    height: 505px;
    img {
      width: 635px;
      height: 505px;
    }
  }
}
.materials {
  .content-image {
    width: 570px;
    height: 570px;
    img {
      width: 570px;
      height: 570px;
    }
  }
}
.personally {
  .content-image {
    width: 570px;
    height: 776px;
    img {
      width: 570px;
      height: 776px;
    }
  }
}

.calendar {
  .content-image {
    width: 570px;
    height: 570px;
    img {
      width: 570px;
      height: 570px;
    }
  }
}

.owl-nav {
  position: absolute;
  top: calc(50% - 35px / 2);
  width: 100%;
  .owl-next {
    position: absolute;
    right: 35px;
  }
  .owl-prev {
    position: absolute;
    left: 35px;
  }
}

.arrow-prev,
.arrow-next {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: url("/img/arrow.svg") no-repeat center center;
}
.arrow-prev:hover,
.arrow-next:hover {
  box-shadow: 0px 3px 15px rgba(81, 81, 81, 0.44);
}
.arrow-next {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.owl-next:focus,
.owl-prev:focus {
  outline: none;
}
.owl-dots {
  margin: 0 auto;
  position: absolute;
  bottom: 5px;
  left: calc(50% - 50px);
  padding: 2px;
}
.owl-carousel button.owl-dot {
  position: relative;
  margin-right: 10px;
  width: 0.5em;
  height: 0.5em;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);
  background: #fff;
  border-radius: 100%;
  padding: 1px !important;
  box-sizing: border-box;
}
.owl-carousel button.owl-dot:focus {
  outline: none;
}
.owl-carousel button.active {
  background: #f47921;
}
