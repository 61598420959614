@import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700,800&display=swap");
@import url("https://fonts.googleapis.com/css?family=Charm&display=swap");

* {
  font-family: "Montserrat", sans-serif;
}

@import "/style/reset.scss";
@import "/style/begin.scss";
@import "/style/header.scss";
@import "/style/concept.scss";
@import "/style/design.scss";
@import "/style/print.scss";
@import "/style/materials.scss";
@import "/style/calendar.scss";
@import "/style/personally.scss";
@import "/style/references.scss";
@import "/style/contact.scss";
@import "/style/footer.scss";
@import "/style/slick.scss";
@import "/style/adaptive.scss";

.wraper {
  margin: 0 auto;
  max-width: 1110px;
}

.section-title {
  padding-top: 115px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.section-content {
  padding-top: 80px;
  display: flex;
  justify-content: space-between;
}
.content-text {
  &__paragraph {
    padding-bottom: 25px;
    font-weight: normal;
    font-size: 14px;
    line-height: 27px;
  }
  &__title {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
  }
  &__list {
    position: relative;
    padding: 35px 0 50px 20px;
  }
  &__list-item {
    margin-bottom: 18px;
    position: relative;
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
  }
  &__list-item:before {
    content: "";
    position: absolute;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: orange;
    top: 10px;
    left: -15px;
  }
}
.title-quote {
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 36px;
  &__text {
    font-family: Charm;
  }
}
.title-headline {
  font-weight: 600;
  font-size: 36px;
  line-height: 54px;
  display: flex;
  align-items: baseline;
  &__image {
    padding-right: 10px;
  }
}

.bold {
  font-weight: bold;
}
button {
  border: none;
}
button:focus {
  outline: none;
}
button:hover {
  cursor: pointer;
}

#impressum {
  padding-bottom: 80px;
  padding-top: 100px;
  background: #fce0cc;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  h1 {
    padding: 40px 0;
    font-weight: 600;
    font-size: 36px;
    line-height: 54px;
  }
  h2 {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    padding-top: 50px;
    padding-bottom: 20px;
  }
  ul {
    padding-left: 15px;
    list-style: circle;
  }
  .impressum-block:nth-child(2) {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
  }
}
